import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { IPermissionsMap } from '@app/shared/types/interfaces/permissions.interface';
import {
  creatingCompanyOnboardingPath,
  loginPath,
  capTableAddSharesPath,
  getCapTableEditSharesPath,
  ScenarioModelingPath,
  ScenarioModelingCreateScenarioPath,
  getScenarioModelingViewScenarioPath,
  getScenarioModelingEditScenarioPath,
  SECTION_PATH,
  settingsExternalSourcesPath,
  documentsOnboardingPath,
} from '@app/shared/utils/constants/paths.constants';
import { PermissionsService } from '@app/shared/services/permissions.service';
import { Section } from '@app/shared/types/enums/section.enum';
import { SECTIONS_NAVIGATION_MIXED_ORDER } from '@app/shared/utils/constants/sections.constants';
import { ParticipantRouteNames } from '@app/participant/types/enums/participant-route-names.enum';
import { ExternalSource } from '@wevestr/bff-types/models/enums/externalSource.enum';

@Injectable({
  providedIn: 'root',
})
export class SectionsNavigationService {
  constructor(private router: Router, private permissionsService: PermissionsService) {}

  public navigateToFirstAvailableSection$(): Observable<boolean> {
    return this.permissionsService
      .getPermissionMap$()
      .pipe(switchMap((permissionMap) => this.navigateToFirstAvailableSection(permissionMap)));
  }

  public navigateToFirstAvailableSection(permissionMap: IPermissionsMap): Promise<boolean> {
    const firstAvailableSection = this.getFirstAvailableSection(permissionMap);
    const sectionPath = this.getSectionPath(firstAvailableSection);

    if (sectionPath) {
      return this.router.navigate(sectionPath);
    }
    return this.router.navigate([ParticipantRouteNames.PARTICIPANT]);
  }

  private getFirstAvailableSection(permissionMap: IPermissionsMap): Section {
    return SECTIONS_NAVIGATION_MIXED_ORDER.find((section) =>
      this.permissionsService.hasAccessToSection(section, permissionMap),
    );
  }

  private getSectionPath(section: Section): string[] {
    return SECTION_PATH[section];
  }

  public navigateToExternalSources(source?: ExternalSource): Promise<boolean> {
    const path = settingsExternalSourcesPath;
    if (source) {
      path.push(source);
    }
    return this.router.navigate(path);
  }

  public navigateToCompanyOnboarding(token: string): Promise<boolean> {
    return this.router.navigate(creatingCompanyOnboardingPath, { queryParams: { token } });
  }

  public naviagateToDocumentsOnboarding(): Promise<boolean> {
    return this.router.navigate(documentsOnboardingPath);
  }

  public navigateToLogin(): Promise<boolean> {
    return this.router.navigate(loginPath);
  }

  public navigateToAddShares(referrer?: string): Promise<boolean> {
    return this.router.navigate(capTableAddSharesPath, {
      queryParams: referrer ? { referrer } : {},
    });
  }

  public navigateToEditShares(id: number, referrer?: string): Promise<boolean> {
    return this.router.navigate(getCapTableEditSharesPath(id), {
      queryParams: referrer ? { referrer } : {},
    });
  }

  public navigateToScenarioModeling(): Promise<boolean> {
    return this.router.navigate(ScenarioModelingPath);
  }

  public navigateToCreateScenario(): Promise<boolean> {
    return this.router.navigate(ScenarioModelingCreateScenarioPath);
  }

  public navigateToEditScenario(id: number): Promise<boolean> {
    return this.router.navigate(getScenarioModelingEditScenarioPath(id));
  }

  public navigateToViewScenario(id: number): Promise<boolean> {
    return this.router.navigate(getScenarioModelingViewScenarioPath(id));
  }

  public navigateTo(path: string[]): Promise<boolean> {
    return this.router.navigate(path);
  }
}
