import { Pipe, PipeTransform } from '@angular/core';
import { CurrencySettingsService } from '@app/shared/services/currency-settings.service';

@Pipe({
  name: 'renderCurrency',
})
export class RenderCurrencyPipe implements PipeTransform {
  constructor(private currencySettingsService: CurrencySettingsService) {}

  public transform(value: number): string {
    return this.currencySettingsService.format(value);
  }
}
