<div class="margin-l">
  <div class="flex-row is-justify-content-space-between is-align-items-center margin-bottom-l">
    <div class="app-h4-medium-weight" matTooltip="{{ document.source.title }}" [matTooltipClass]="['info-tooltip', 'info-tooltip_top-alignment']">{{ document.source.title }}</div>
    <mat-icon svgIcon="cross" class="cursor-pointer close-icon" (click)="handleClose()"></mat-icon>
  </div>
  <div class="flex-column is-justify-content-center is-align-items-center">
    <ng-container *ngIf="documentURL">
      <object *ngIf="isPdf" [data]="documentURL" type="application/pdf" [width]="600" [height]="500">
        <a [href]="documentURL">document.pdf</a>
      </object>
      <img *ngIf="isImage" [src]="documentURL" />
    </ng-container>
  </div>
</div>
