import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ITransactionDTO } from '@app/shareholder-management/types/interfaces/transaction.interface';
import { ConfigService } from '@app/services/config.service';
import { IParticipant } from '@app/shared/types/interfaces';
import { ITransaction } from '@app/shared/types/interfaces/transaction.interface';
import {
  IManageSharesDateValidationDetails,
  IManageSharesDateValidationResponse,
  IManageSharesFirstAvailableDate,
} from '@app/manage-shares/types/interfaces/manage-shares-date-validation-details.interface';
import { COMPANY_ID_TOKEN } from '@app/services/company-id/company-id.constants';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  constructor(private http: HttpClient, private config: ConfigService) {}

  private url(): string {
    return `${this.config.getFullBaseUrl()}/companies/${COMPANY_ID_TOKEN}/cap-table/transactions`;
  }

  public create(transactionDTO: ITransactionDTO): Observable<void> {
    return this.http.post<void>(this.url(), transactionDTO);
  }

  public edit(transactionDTO: ITransactionDTO): Observable<void> {
    return this.http.post<void>(`${this.url()}/edit`, transactionDTO);
  }

  public getTransactionDetails(transactionId: number): Observable<ITransaction> {
    return this.http.get<ITransaction>(`${this.url()}/${transactionId}`);
  }

  public getTransactions(groupId: number): Observable<ITransaction[]> {
    const params = {
      groupId,
    };
    return this.http.get<ITransaction[]>(`${this.url()}/`, { params });
  }

  public getCapTableParticipations(): Observable<IParticipant[]> {
    return this.http.get<IParticipant[]>(
      `${this.config.getFullBaseUrl()}/companies/${COMPANY_ID_TOKEN}/cap-table/participants`,
    );
  }

  public deleteTransaction(transactionId: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.url()}/${transactionId}`);
  }

  public deletePhase(phaseId: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.url()}/delete-rounds/${phaseId}`);
  }

  public validateDateIssued(
    dateValidationDetails: IManageSharesDateValidationDetails,
  ): Observable<IManageSharesDateValidationResponse> {
    return this.http.post<IManageSharesDateValidationResponse>(
      `${this.url()}/validate-date-of-issue`,
      dateValidationDetails,
    );
  }

  public getFirstAvailableDateForSharesWithValuation(
    transactionId: string,
  ): Observable<IManageSharesFirstAvailableDate> {
    const params = transactionId ? { transactionId } : {};
    return this.http.get<IManageSharesFirstAvailableDate>(
      `${this.url()}/first-available-date-for-shares-with-valuation`,
      { params },
    );
  }
}
