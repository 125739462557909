import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TAllowedInputType } from '@app/forms/types';

@Component({
  selector: 'wevestr-combined-select-input',
  templateUrl: './combined-select-input.component.html',
  styleUrls: ['./combined-select-input.component.scss'],
})
export class CombinedSelectInputComponent {
  @Input() public inputControl: AbstractControl;
  @Input() public selectionControl: AbstractControl;
  @Input() public selectOptions: { name: string }[];
  @Input() public customRequiredError: string;
  @Input()
  public label: string;
  @Input()
  public inputPlaceholder = '';
  @Input()
  public selectPlaceholder = '';
  @Input()
  public disabled: boolean;
  @Input()
  public type: TAllowedInputType = 'text';
  @Input()
  public mask: string;
  @Input() displayFn = (item: { name: string }): string => item.name;
  @Input() public inputDataId: string;
  @Input() public selectDataId: string;
  @Input() public selectMenuDataId: string;
  @Input() public isRequired = false;
  @Input() public tooltipText: string;

  @Output() public reset = new EventEmitter();

  public readonly ERRORS = {
    REQUIRED: 'Both Valuation Amount and Valuation Type are required if one is entered.',
  };
}
