import { SafeHtmlPipe } from '@app/shared/pipes/safe-html.pipe';
import { IsDefinedPipe } from '@app/shared/pipes/is-defined.pipe';
import { PercentageCalculationPipe } from '@app/shared/pipes/percentage-calculation.pipe';
import { BooleanLabelPipe } from '@app/shared/pipes/boolean-label.pipe';
import { DateFormatPipe } from '@app/shared/pipes/date-format.pipe';
import { DateToLocalFormatPipe } from '@app/shared/pipes/date-to-local-format.pipe';
import { FormatPercentagePipe } from '@app/shared/pipes/format-percentage.pipe';
import { NumberFormatPipe } from '@app/shared/pipes/number-format.pipe';
import { CompanyRoleLabelPipe } from '@app/shared/pipes/company-role-label.pipe';
import { RenderNonZeroCurrencyPipe } from '@app/shared/pipes/render-non-zero-currency.pipe';
import { RenderCurrencyPipe } from '@app/shared/pipes/render-currency.pipe';
import { CapitalizeFirstLetterPipe } from '@app/shared/pipes/capitalize-first-letter.pipe';
import { IndicatorFormatPipe } from '@app/shared/pipes/indicator-format.pipe';
import { Format2faSecretCodePipe } from '@app/shared/pipes/format-code.pipe';
import { FormatToFileSizeStringPipe } from '@app/shared/pipes/format-to-file-size-string.pipe';
import { DocumentExtensionIconPipe } from '@app/shared/pipes/document-extension-icon.pipe';
import { IsNotEmptyArrayPipe } from '@app/shared/pipes/is-not-empty-array.pipe';
import { IsEmptyArrayPipe } from '@app/shared/pipes/is-empty-array.pipe';
import { PhaseOrderErrorFormatPipe } from '@app/shared/pipes/phase-order-error-format.pipe';
import { StakeholderAvatarListPipe } from '@app/shared/pipes/stakeholder-avatar-list-item.pipe';

export const pipes = [
  SafeHtmlPipe,
  IsDefinedPipe,
  PercentageCalculationPipe,
  BooleanLabelPipe,
  NumberFormatPipe,
  FormatPercentagePipe,
  CompanyRoleLabelPipe,
  DateFormatPipe,
  DateToLocalFormatPipe,
  RenderNonZeroCurrencyPipe,
  RenderCurrencyPipe,
  CapitalizeFirstLetterPipe,
  IndicatorFormatPipe,
  Format2faSecretCodePipe,
  FormatToFileSizeStringPipe,
  IsNotEmptyArrayPipe,
  DocumentExtensionIconPipe,
  IsEmptyArrayPipe,
  PhaseOrderErrorFormatPipe,
  StakeholderAvatarListPipe,
];

export * from '@app/shared/pipes/safe-html.pipe';
export * from '@app/shared/pipes/is-defined.pipe';
export * from '@app/shared/pipes/percentage-calculation.pipe';
export * from '@app/shared/pipes/boolean-label.pipe';
export * from '@app/shared/pipes/number-format.pipe';
export * from '@app/shared/pipes/format-percentage.pipe';
export * from '@app/shared/pipes/company-role-label.pipe';
export * from '@app/shared/pipes/date-format.pipe';
export * from '@app/shared/pipes/date-to-local-format.pipe';
export * from '@app/shared/pipes/render-non-zero-currency.pipe';
export * from '@app/shared/pipes/render-currency.pipe';
export * from '@app/shared/pipes/indicator-format.pipe';
export * from '@app/shared/pipes/format-code.pipe';
export * from '@app/shared/pipes/format-to-file-size-string.pipe';
export * from '@app/shared/pipes/is-not-empty-array.pipe';
export * from '@app/shared/pipes/document-extension-icon.pipe';
export * from '@app/shared/pipes/is-empty-array.pipe';
export * from '@app/shared/pipes/phase-order-error-format.pipe';
export * from '@app/shared/pipes/stakeholder-avatar-list-item.pipe';
